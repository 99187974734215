<template>
  <div>


    <a-table
      class="dataMonitor-table"
      :columns="columns"
      :dataSource="dataSource"
      size="middle"
      :pagination="false">

      <template slot="identifier" slot-scope="text, record">
        <a-tag color="green"> {{text}} </a-tag>
      </template>

      <template slot="value" slot-scope="text, record">
        {{record.value ? record.value+"" : "-"}}
      </template>

      <template slot="action" slot-scope="text, record">
        <a-button size="small" type="primary" ghost v-if="record.accessMode == 'rw' " @click="showModal(record)">控制 </a-button>
        <a-button size="small" type="primary" ghost v-else disabled>只读 </a-button>
      </template>

    </a-table>

    <device-para-control ref="deviceParaControl"></device-para-control>

  </div>
</template>

<script>
  import DeviceParaControl from './DeviceParaControl'
  export default {
    components:{DeviceParaControl},
    props: ['iotId'],
    data () {
      return {
        dataSource: [],
        value: '',
        visible: false,
      }
    },
    mounted () {
      //获取设备属性定义
      this.$get(`device/${this.iotId}/tsl`, {}).then(r => {
        r.data.properties.forEach(item=>{
          item.value=""
        })
        this.dataSource = r.data.properties

        //获取设备属性值
        this.getProperties()
      })
    },

    computed: {
      columns () {
        return [
          {
            title: '参数名称',
            dataIndex: 'name',
          },
          {
            title: '参数标识',
            dataIndex: 'identifier',
            scopedSlots: {customRender: 'identifier'}
          },
          {
            title: '参数值',
            dataIndex: 'value',
            scopedSlots: {customRender: 'value'}
          },
          {
            title: '单位',
            dataIndex: 'dataType.specs.unit',
            key: 'unit'
          },
          {
            title: '操作',
            key: 'action',
            scopedSlots: {customRender: 'action'}
          }
        ]
      }
    },
    methods: {
      getProperties () {

        this.$get(`device/${this.iotId}/properties`, {}).then(r => {
          let valueMap = {}
          r.data.forEach(item => {
            valueMap[item.attribute] = item.value
          })

          this.dataSource.forEach(item => {
            item.value = valueMap[item.identifier]
          })

          let data = this.dataSource
          this.dataSource = []
          this.dataSource = data

        })

      },
      showModal (record) {
        // this.visible = true
        console.log(record)
        console.log(record.dataType.specs)

        this.$refs.deviceParaControl.show(record,value=>{
            this.$post(`device/${this.iotId}/properties`, {
              key:record.identifier,
              value:value,
              type:record.dataType.type
            }).then(r => {
              setTimeout(()=>{
                this.getProperties()
              },1000)
            })
        })
      },

    }
  }
</script>

<style>

</style>
